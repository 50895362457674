.modal-content{
  width:800px;
  max-height:95%;
  overflow-y:auto;
  padding:30px;
  background-color:#fff;
  border-radius:10px;
  &__heading{
    display:block;
    font-weight:bold;
    margin-bottom:15px;
    border-bottom:1px solid #333;
    padding-bottom:8px;
    margin-top:0;
    font-size:22px;
  }
}
.modal-select{
  margin-bottom:20px;
  & span{
    display:block;
    margin-bottom:5px;
  }  
}
.hw-list{
  list-style:none;
  margin-bottom:70px;
  & > li{
    margin-bottom:12px;
    position:relative;
  }
}
.hw-item{
  border-radius:3px;
  border:1px solid #ccc;
  padding:10px 10px;
  &__right{
    position:absolute;
    top:10px;
    right:15px;
    width:30px;
    height:30px;
    border-radius:50%;
    border:1px solid #ccc;
    display:flex;
    justify-content: center;
    align-items:center;
    background-color:#fff;
    cursor:pointer;
    &:hover{
      background-color:#f3f3f3;
    }
  }
  &.active .hw-item__right{
    rotate: -90deg;
  }
  &__top{
    font-weight:bold;
    font-size: 16px;
    line-height: 24px;
  }
  &__question{
    display:block;
    font-size: 14px;
    margin-bottom:6px;
    font-weight:bold;
  }
  &__area{
    max-height:0;
    overflow:hidden;
  }
  &.active .hw-item__area{
    max-height:7000px;
  }
}
.homework-list{
  margin-top:12px;
  & > li{
    border-top:1px solid #ccc;
    padding-top:9px;
    margin-top:9px;
  }
  & .question-row__area{
    margin-bottom:2px;
  }
}
.mininput-row{
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  & input{
    margin-right:3px;
  }
  & label{
    font-size:13px;
  }
}
.btn-modal{
  position:fixed;
  bottom:30px;
  background-color:#fff;
  height:70px;
  padding-top:5px;
  width:730px;
  & .btn{
    width:400px;
  }
}
.btn-green{
  background-color:green;
}
.edit-form-row__priceinput{
  width:150px;
  margin-right:15px;
}
.radio-label .radio-label__input{
  width:15px;
  height:15px;
}