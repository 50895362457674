.my-course {
  .btn-group {
    padding: 10px 0;
    gap: 15px;
    .btn {
      display: flex;
      width: auto;
      padding: 18px 36px;
      align-items: center;
      justify-content: space-around;
      font-size: 16px;
      line-height:1;
    }
    i{
      margin-left: 10px;
    }
  }
}
