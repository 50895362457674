.prise-item{
  &__des{
    height:100%;  
    padding:30px 30px 110px 30px;   
  }
  &__bot{
    position:absolute;
    bottom:20px;
  }
  &__heading{
    display: block;
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 14px;
  }
  &__pricepart{
    display:flex;
    align-items:center;
    margin-bottom:18px;
  }
  &__price{
    color:#3561FF;
    display:block;
    font-weight:bold;
    font-size:24px;
    font-family: "Rothek Bold", sans-serif;
    margin-right:15px;
  }
  &__studycoins{
    display:flex;
    align-items:center;
    & span{
      margin-left:10px;
    }
  }
} 

