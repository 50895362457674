.navbar {
  grid-area: navbar;
  display: grid;
  grid-template-rows: auto 1fr auto;
  border-right: 1px solid #eff0f4;
  background-color:#fff;  
  &--active{
    display:block;
  }
  &__area{
    height:100%;
    overflow:auto;
  }
}
.logo {
  height: 98px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #eff0f4;
  padding:18px;
  & img{
    display:block;
    max-height:80%;
    max-width:90%;
  }
}
nav {
  display: grid;
  display: flex;
  flex-direction: column;
  transition: 0.2s linear;
  .active {
    color: #3561ff;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      background: #3561ff;
      border-radius: 5px 0px 0px 5px;
      height: 100%;
      width: 4px;
      animation: translateAnimation 0.2s;
    }
  }
  a {
    display: block;
    width: 100%;
    padding: 30px 20px;
    border-bottom: 1px solid #eff0f4;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #343c58;
    svg {
      margin: 0 10px;
      max-width:24px;
      max-height:24px;
    }
    &:hover{
      color: #3561ff;
    }
  }
}
.logout {
  color: #ff0000;
  padding: 35px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin: 0 10px;
  }
}
@keyframes translateAnimation {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.help {
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  p {
    color: #343c58;
    font-weight:bold;
    margin-bottom:5px;
  }
  .arrow-turn-down {
    color: #6e758a;
    display: flex;
    align-items: center;
    margin-bottom:15px;
    small {
      text-decoration: underline;
    }
    i {
      padding: 0 10px;
    }
  }
  .btn {
    display: flex;
    align-items: center;
    max-width: 215px;
    justify-content: space-around;
    background-color: #F5F7FF;
  }
}
@media screen and (max-width: 1600px) {
  .navbar .logo {
    height: 80px;
  }
  nav a{
    padding: 28px 20px;
  }  
}
@media screen and (max-width: 1100px) {
  .navbar{
    position:fixed;
    width:380px;
    top:70px;
    bottom:0;
    left:-380px;
    z-index:999;
    transition: left 0.5s ease-in-out;
    .logo{
      display:none;
    } 
  }
  .navbar.navbar--active {
    display: block;
    left:0
  }
}
@media screen and (max-width: 960px) {
  nav a,.logout {
    padding: 22px 20px;
  }
  .help .btn{
    padding: 16px 10px;
    font-size: 15px;
  }
}