.popup-form{
  display:block;
  & .edit-form-row{
    margin-bottom:12px;
  }
  & .input{
    padding:15px;
  }
}
.edit-form-row__label{
  font-weight:bold;
}
.edit-form-row p{
  font-size:15px;
}
.edit-form-row--small input{
  height:22px;
  font-size:15px;
}
.popup-form .flex{
  margin-bottom:5px;
  font-size:15px;
  align-items:center;
  & label{
    margin-bottom:0;
  }
}
.edit-form-row--flex{
  display:flex;
  align-items:center;
  & label{
    margin-right:5px;
  }
}
.edit-form-row__label--left{
  float:left;
  width:120px;
  text-align:right;
  margin-right:5px;
}
.edit-form-row__right{
  margin-left:130px;
}
.pricelist-ul{
  list-style:none;
  font-size:15px;
  &--flex{
    display:flex;
    & li{
      margin-right:15px;
    }
  }
  & li{
    margin-bottom:2px;
  }
}
.position-row input{
  margin-right:3px;
}
.modal-content__heading2{
  display:flex;
  align-items:center;
  justify-content:space-between;
  padding-bottom:15px;
  margin-bottom:20px;
  border-bottom:1px solid #000;
  font-weight:bold;
  & h1{
    font-size:24px;
    font-weight:bold;
    line-height:24px;
    margin:0;
  }
}
.edit-courses{
  list-style:none;
  & li{
    margin-bottom:7px;
  }
}