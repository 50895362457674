.course-nav{
    display:flex;
    list-style:none;
    border-bottom:1px solid #ccc;
    margin-bottom:30px;
}
.course-nav__link{
    display:block;
    padding:12px 20px;
    border-top-left-radius:4px;
    border-top-right-radius:4px;
    margin-right:10px;
    text-decoration:none;
}
.course-nav__link:hover{
    background-color:#fff;
}
.course-nav__link.active{
    background-color: #fff;
    border: 1px solid #ccc;
    border-bottom: none;
}
