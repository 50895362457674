.ref-top-ul{
  list-style:none;
  margin-bottom:15px;
  & li{
    margin-bottom:5px;
  }
}
.buy-part{
  width:900px;
  position:relative;
  margin-bottom:50px;
  &__heading{
    display:block;
    font-weight:bold;
    font-size:25px;
    margin-bottom:15px;
  }
}
.buy-step{
  margin-bottom:20px;
  &__heading{
    display:block;
    font-weight:bold;
    margin-bottom:13px;
    font-size:16px;
  }
}
.buy-list{
  display:flex;
  list-style:none;
  flex-wrap: wrap;
  margin-right: -3%;
}
.buy-list li{
  width:30%;
  margin-right:3%;
  margin-bottom:15px;
}
.buy-item{
  background-color:#f1efff;
  padding:11px;
  border-radius:5px;
  display:flex;
  align-items:center;
  padding: 0 10px;
  height: 55px;
  cursor:pointer;
  &__checkbox{
    border:1px solid #999;
    width:22px;
    height:22px;
    border-radius:2px;
    margin-right:10px;
    background-color:#fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &--active{
    background-color: #dddaf1;
  }
  &:hover{
    background-color: #dddaf1;
  }
}

.buy-end{
  border-top:2px solid #000;
  padding-top:20px;
  &__info{
    margin-bottom:20px;
    display:flex;
    & div{
      margin-right:30px;
      & span{
        font-weight:bold;
        margin-right:5px;
      }
    }
  }
}
.cross{
  text-decoration:line-through;
  display:inline;
}

@media screen and (max-width: 1000px) {
  .buy-part{
    width:100%;
  }
  .buy-item{
    font-size:15px;
  }
}
@media screen and (max-width: 750px) {
  .buy-list{
    margin-right:-5%;
    & li{
      width:45%;
      margin-right:5%;
      margin-bottom:10px;
    }
  }
  .buy-end__info{
    flex-wrap:wrap;
    & div{
      width:100%;
      margin-right:0;
      margin-bottom:8px;
    }
  }
}