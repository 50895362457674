.analize-top{
  margin-bottom:20px;
  &__heading{
    margin:0 0 10px 0px;
    font-weight:bold;
    font-size:24px;
    font-family: "Rothek Bold", sans-serif;
  }
}
.analytics-panel{
  padding:20px 0 !important;
  &__heading{
    display:block;
    margin-bottom:15px;
  }
}
.anal-ul{
  display:flex;
  list-style:none;
  flex-wrap:wrap;
  margin-right:-20px;
  & li{
    width:33.2%;
    margin-bottom:20px;
  }
}
.lesson-analize{
  display:block;
  border:1px solid #ccc;
  margin-right:20px;
  height:100%;
  padding:15px;
  border-radius:5px;
  color:#222;
  text-decoration:none;
  &__heading{
    display:block;
    font-weight:bold;
    font-size:18px;
    margin-bottom:10px;
  }
  &__row{
    display:flex;
    font-size:15px;
    margin-bottom:4px;
    & p{
      margin-right:5px;
    }
  }
  &:hover{
    background-color:#f3f3f3;
  }
  & .btn{
    display:inline-block;
    margin-top:10px;
    padding:10px 15px;
    font-size:14px;
    width:auto;
  }
}
@media screen and (max-width: 800px) {
  .anal-ul li {
    width: 50%;
  }  
}
@media screen and (max-width: 600px) {
  .anal-ul li {
    width: 100%;
  }  
}