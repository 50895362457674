.true-false{
  display:flex;
  margin-bottom:20px;
  &__area{
    display:flex;
    margin-right:15px;
  }
}
.task_attachment-top{
  margin-top:-15px;
}