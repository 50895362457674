.subject-list{
  list-style:none;
  display:flex;
  margin-right:-20px;
  flex-wrap:wrap; 
  & li{
    width:33.3%;
    margin-bottom:25px;
  }
}
.subject-hw{
  margin-right:20px;
  &__heading{
    display:block;
    font-weight:bold;
    font-size:18px;
    margin-bottom:15px;
  }
  &__done{
    display:block;
    margin-bottom:10px;
    & span{
      font-weight:500;
    }
  }
  & .my-btn{
    margin-top:15px;
  }
}
