.admin-top{
  margin-bottom:25px;
}
.user-payements-list{
  display:flex;
  list-style:none;
  flex-wrap:wrap;
  & li{
    width:33.3%;
  }
}
.admin-pay-item{
  padding:20px;
  border-radius:12px;
  background-color:#fff;
  margin-right:20px;
  margin-bottom: 20px;
  &__top{
    display:flex;
    align-items: center;
    justify-content:space-between;
    margin-bottom:15px;
  }
  &__heading{
    display:block;
    font-size:18px;
    font-weight:bold;
  }
  &__date{
    background-color: #f3f3f3;
    padding: 5px 12px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 5px;
  }
  & .btn{
    background-color: #315af0;
    color: #fff;
    padding: 10px 20px;
    margin-top: 12px;
    &:hover{
      background-color: #1a44de;
    }
  }
}
.les-row--nobot{
  margin-bottom:0;
  display:flex;
  align-items:center;
}
.status-top{
  padding:15px;
  margin-bottom:25px;
}
.pay-row{
  margin-bottom:8px;
  font-size:15px;
  &--last{
    margin-bottom:0;
  }
  & span{
    font-weight:600;
  }
}
.les-radio{
  display:flex;
  margin-right:15px;
  align-items: center;
  & input{
    width:20px;
    height:20px;
    margin-right:5px;
  }
}
.ocourse-ul{
  margin-top:5px;
  list-style:none;
  & li{
    border-radius:3px;
    margin-bottom:5px;
    &:before{
      content:"•";
      font-weight:bold;
      margin-right:5px;
    }
  }
}
.btn-grey{
  background-color:#555;
  padding:5px 12px;
  font-size:15px;
  &:hover{
    background-color:#333;
  }
}