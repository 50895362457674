.teachers-list {
    display: flex;
    flex-wrap:wrap;
    list-style:none;
    margin-right:-30px;
    & li{
        width:25%;
        margin-bottom:30px;
    }
}

@media screen and (max-width: 960px) {
    .teachers-list li{
        width:33.3%;
    }
}

@media screen and (max-width: 800px) {
    .teachers-list li{
        width:50%;
    }
}
@media screen and (max-width: 540px) {
    .teachers-list{
        margin-right:-20px;
    }
    .teacher-item{
        margin-right:20px;
    }
    .teacher-item .image-wrapper {
        position: relative;
        height: 205px;
    }    
}
@media screen and (max-width: 540px) {
    .teacher-item .teacher-info {
        padding: 14px 15px 15px 15px;
    }
    .teacher-item .teacher-info__subject{
        font-size:18px;
    }    
}