.course-top{
  display:flex;
  align-items:center;
  margin-bottom:25px;
  &__left{
    width:34%;
    height:290px;
    border-radius:4px;
    overflow:hidden;
    flex-shrink: 0;
    & img{
      display:block;
      width:100%;
      height:100%;
      object-fit:cover;
    }
  }
  &__content{
    margin-left:30px;
  }
  &__heading{
    display:block;
    font-family: "Rothek Bold", sans-serif;
    color: #343c58;
    font-weight: bold;
    font-size: 32px;
    margin-bottom:15px;
  }
  &__text{
    margin-bottom:18px;
    & p{
      line-height:1.5;
    }
  }
}
.include-list{
  list-style:none;
  margin-bottom: 22px;
  & li{
    margin-bottom:10px;
    color:#A8ACB9;
    & i{
      margin-right:5px;
    }
    & span{
      color:#6E758A;
      margin-left: 5px;
    }
  }
}
@media screen and (max-width: 1600px) {
  .course-top{
    &__left{
      width:33%;
      height:250px;
    }
    &__heading{
      font-size:28px;
      margin-bottom:10px;
    }
    &__text p{
      font-size:15px;
    }
  }
  .include-list li{
    font-size:15px;
  }
}
