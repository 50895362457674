.btn {
  border-radius: 5px;
  border: 0;
  width: 100%;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  transition: 0.05s linear;
  padding: 20px;
}
.btn-mini{
  padding: 8px;
  font-size: 12px;
  width: 170px;
}
.btn-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.btn-primary {
  background-color: #3561ff;
  font-weight: 700;
  &:hover {
    background-color: #315af0;
  }
  &:active {
    background-color: #2a4ecf;
  }
}
.btn-delete {
  width: 160px;
  padding: 13px;
  font-size: 14px;
}
.btn-outlined {
  border: 1px solid #3561ff;
  background-color: transparent;
  color: #3561ff;
  font-size:15px;
  font-weight:500;
  &:hover{
    background-color:#3561ff;
    color:#fff;
  }
  &:active {
    background-color: #2d55e7;
    color: #fff;
  }
}
.btn-dashed {
  border: 1px dashed #3561ff;
  border-radius: 4.08333px;
  color: #3561ff;
  background-color: transparent;
  &:hover{
    background-color: #3561ff !important;
    color:#fff;
  }
  &:active {
    border-style: solid;
  }
}
.btn-inline{
  display:inline-block;
  width: auto;
}
.btn-group {
  display: flex;
  width: fit-content;
  height: fit-content;
}

.btn-telegram {
  background-color: #259bd8;
  display: flex;
  align-items: center;
  justify-content: space-around;
  &:hover {
    background-color: #1f89be;
  }
  &:active {
    background-color: #1c7eaf;
  }
}
.btn-area{
  margin-bottom:20px;
}
.btn-white {
  background-color:#fff;
  color:#333;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0px rgba(49, 53, 72, 0.05);
  & i{
    margin-left:5px;
    font-size: 14px;
  }
}
.btn-white:hover{
  box-shadow: 1px 1px 9px 1px rgb(49 53 72 / 14%)
}
.btn-success {
  border: 1px solid #20945c;
  color: #20945c;
  &:hover {
    border-color: #105333;
  }
  &:active {
    background-color: #20945c;
    color: white;
  }
}
