.modal-photo{
  width:100vw;
  height:100vh;
  background-color: rgb(0 0 0 / 40%);
  position:fixed;
  top:0;
  left:0;
  display:flex;
  align-items:center;
  justify-content: center;
  transform: scale(0);
  z-index:100;
  &.active{
    transform:scale(1);
  }
  &__content{
    max-width:900px;
    min-width:600px;
    padding:30px;
    border-radius:12px;
    background-color:white;
    & img{
      display:block;
      width:100%;
    }
  }  
}

.modal__heading{
  display:block;
  padding-bottom:10px;
  border-bottom:1px solid #ccc;
  font-size: 24px;
  color: #343C58;
  font-family: "Rothek Bold", sans-serif;
  font-weight: bold;
  margin-bottom:20px;
}

