.app-container {
  display: grid;
  grid-template-rows: 80px 1fr auto;
  gap: 50px;
  grid-template-areas:
    "header"
    "container"
    "footer";
  .content-wrapper {
    display: grid;
    justify-content: center;
    align-items: center;
  }
}
.login-form__des{
  line-height:1.5;
}

@media screen and (max-width: 1200px) {
  .login-container{
    width:800px;
  }
  .login-container img{
    width:400px;
    object-fit:cover;
  }
  .app-container{
    position:relative;
  }
}
@media screen and (max-width: 960px) {
  .app-container{
    display:block;
    padding-bottom:80px;
    position:relative;
    min-height:100%;
  }
  .content-wrapper{
    padding:40px 40px;
    display:block;
  }
  .login-container {
    width:100%;
    max-width: 720px;
  }
  .login-container img{
    display:none;
  }
  .login-form {    
    padding: 50px 50px 80px 50px;
  }
}
@media screen and (max-width: 600px) {
  .content-wrapper {
    padding: 30px 30px;
  }  
  .login-form {    
    padding: 40px 40px 60px 40px;
  }
  .login-form h1 {
    display: block;
    font-size: 20px;
  }  
}
@media screen and (max-width: 460px) {
  .content-wrapper {
    padding: 25px 25px;
  }
  .login-form {    
    padding: 30px 30px 50px 30px;
  }
}    