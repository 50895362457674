.homework {
  margin-bottom: 30px;
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eff0f4;
    padding-bottom: 20px;
    margin-bottom: 25px;
  }
  &__heading {
    font-weight: bold;
    color: #343c58;
    font-size: 22px;
    line-height: 1.45;
    font-family: "Rothek Bold", sans-serif;
    margin-bottom: 10px;
    &--zachet{
      margin-bottom:0;
    }
  }
  &__title {
    color: #3561ff;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
  }
}
.homework-ul {
  list-style: none;
  & li {
    margin-bottom: 22px;
  }
}
.q-teacher{
  list-style:none;
}
.question-row {
  position: relative;
  &__heading {
    display: block;
    margin-bottom: 12px;
    font-weight: 600;
    & span {
      color: #3561ff;
      margin-right: 5px;
    }
  }
  &__area {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
  }
}
.input-row {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  & input {
    width: 22px;
    height: 22px;
    background-color: #f7f7f5;
    margin-right: 10px;
    border-radius: 2px;
  }
  & label {
    margin-top: 2px;
    font-size: 15px;
  }
  &--second{
    margin-bottom:15px;
  }
}
.question-task {
  &__text {
    margin-bottom: 25px;
    & p {
      font-size: 15px;
      line-height: 1.5;
    }
  }
}
.question-file {
  margin-bottom: 20px;
  & span {
    display: block;
    margin-bottom: 10px;
    color: #3561ff;
  }
}
.question-textarea {
  background-color: #eff0f4;
  color: #6e758a;
  width: 100%;
  min-height: 80px;
  border: none;
  resize: none;
  padding: 12px 15px;
  border-radius: 4px;
  font-size: 15px;
  border: 1px solid #eff0f4;
}

.task-area {
  position: relative;
  &__input {
    margin-bottom: 14px;
    & label{
      display:block;
      margin-bottom:7px;
    }
  }
  &__textarea {
    border: 1px solid #eff0f4;
    background-color: #f7f7f5;
    width: 100%;
    height: 120px;
    resize: none;
    padding: 20px;
    border-radius: 5px;
  }
}
.task_attachment {
  max-width: 300px;
  max-height:300px;
  display:block;
  margin-bottom:25px;
}
.question__img {
  display: block;
  max-width: 100%;
  min-height: 30px;
}
.question__img img {
  display: block;
  max-width: 100%;
}
.homework-lesson__deadline {
  width: 140px;
  position: absolute;
  top: 38px;
  right: 0;
  background-color: #f10000;
  color: #ffffff;
  font-size: 14px;
  padding: 12px 15px;
  font-weight: 500;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
}
.input-radio__correct {
  background: rgb(4, 216, 21);
  min-width: 100px;
  border-radius: 2px;
  padding: 3px;
}
.input-radio__incorrect {
  background: rgb(254, 52, 52);
  min-width: 100px;
  border-radius: 2px;
  padding: 3px;
}
.homework-done{
  border-top:1px solid #eff0f4;
  padding-top:20px;
  margin-top:20px;
  &__heading{
    display:block;
    margin-bottom:15px;
  }
  &__top{
    display:flex;
    align-items: center;
    justify-content: space-between;
  }
}
.homework-grade{
  display:flex;
  align-items:center;
  &__img{
    margin-right:20px;
    & img{
      height:60px;
    }
  }
  &__heading{
    font-weight:bold;
    margin-bottom:5px;
    font-size:24px;
    font-family: "Rothek Bold", sans-serif;
  }
  &__text span{
    font-weight:bold;
  }
}
.homework-date{
  padding: 8px 30px;
  border: 1px solid #777;
  border-radius: 49px;
  background-color: #f3f3f3;
  font-size: 15px;
  & span{
    font-weight:bold;
  }
}
.legend_grade{
  color: #81CF01;
}
.good_grade{
  color: #C9DA00;
}
.norm_grade{
  color: #F1D900;
}
.bad_grade{
  color: #F63C3C;
}
.check-answer{
  font-weight:bold;
  color: #0ad40a;
  &__wrong{
    color:red;
  }
}
.input-student-solution{
  & label{
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    display: inline-block;
    font-weight: 500;
    border-radius: 3px;
    padding: 8px 16px;
    border: 1px solid #ccc;
    background-color: #3561ff;
    margin-bottom: 0;
    &:hover{
      background-color: #234ce2;
    }
    & i{
      margin-right:5px;
    }
  }
  & input{
    display:none;
  }
}
.att-images{
  display:flex;
  margin-bottom: 10px;
  &__item{
    width:150px;
    max-height:150px;
    margin-right:20px;
    cursor:pointer;
    & img{
      display:block;
      width:100%;
      height:100%;
      &:hover{
        opacity:0.8;
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .homework-done__top {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
  }
  .homework-grade{
    margin-bottom:15px;
    &__img img{
      height:55px;
    }
  }
  .homework-date{
    display:flex;
    border:none;
    padding:0;
    background:transparent;
  }
}
