.adminreal-choice-row__img {
  width: 150px;
  margin-left: 20px;
  margin-top: 15px;
}
.adminreal-choice-row__img img {
  display: block;
  max-width: 100%;
}
.qwestion-admin__title-wrapper {
  display: flex;
}
.qadmin-list {
  border-radius: 8px;
  box-shadow: 0 1px 2px 0px rgb(49 53 72 / 5%);
}
.admin-choice-row__solution{
  margin-bottom:10px;
  font-size:15px;
  margin-left: 15px;
  & label{
    margin-right:5px;
  }
}
.admin-question-flex{
  display:flex;
  justify-content: space-between;
  align-items:center;
}
.solution-row{
  display:flex;
  align-items:center;
  margin-bottom:10px;
  &__input{
    height:25px;
    width:500px;
  }
}