.course-item {
  border-radius: 12px;
  margin-bottom: 20px;
  padding:20px;
  justify-content: left;
  overflow:hidden;
  .image-wrapper {
    width: 200px;
    height:180px;
    float:left;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    margin-right:22px;
    img {
      width: 100%;
      height:100%;
      object-fit:cover;
    }
  }
}
.course-wrapper {
  margin-left:170px;
  position:relative;
  font-size: 14px;
  &__title {
    display:block;
    font-size: 20px;
    color: #343c58;
    font-family: "Rothek Bold", sans-serif;
    margin-bottom:20px;
  }
  i {
    font-size: 16px;
    padding: 0 5px;
  }
  .btn {
    max-width:200px;
    font-size:13px;
    padding: 14px 18px;
  }
  .btn-telegram {
    min-width: 245px;
    img {
      height: 14px;
      margin-left:10px;
    }
  }
  p {
    line-height:1.5;
    max-width: 735px;
    margin-bottom:5px;
    overflow-wrap: break-word;
  }
  
}
.course-bot .btn{
  display:inline-block;
  margin-right:20px;
}
.course-item--inner .image-wrapper{
  width: 150px;
  height: 118px;
  background-color:#f3f3f3;
}
.course-details {
  font-size: 14px;
  & div{
    margin-bottom:15px;
  }
  span {
    color: #a8acb9;
  }
}
.lessons-list {
  padding: 10px;
}
.course-penalty--mini{
  display:flex;
}
.course-penalty img{
  height: 18px;
  width: 18px;
  margin-right: 2px;
}
.course-penalty div{
  margin-right:5px;
}
.show-lesson {
  color: #3561ff;
  font-weight: 700;
  font-size: 14px;
  span {
    font-weight: 500;
    font-size: 13px;
  }
}
@media screen and (max-width: 1100px) {
  .course-details span{
    font-size:0;
    margin-right: 5px;
  }
}
@media screen and (max-width: 700px) {
  .course-item .image-wrapper{
    display:none;
  }
  .course-wrapper .btn{
    position:static;
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
  .course-wrapper {
    margin-left:0px;
  }
}