.teacher-item {
  position:relative;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 20px 28px 39.5px rgba(0, 11, 51, 0.05);
  border-radius: 16.5px;
  margin-right:25px;
  height:100%; 
  .image-wrapper {
    position: relative;
    height: 310px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .teacher-info {    
    border-radius: 0px 0px 16.5px 16.5px;    
    padding: 20px 22px 16px 22px;
    display: flex;
    flex-direction: column;
    margin-bottom:-5px;
    p, a {
      color: #6e758a;
      font-size: 14px;
      line-height: 125%;
      font-weight: 500;
      text-decoration:none;
      margin-bottom:8px;
      i {
        padding-right: 5px;
      }
    }
    a:hover{
      text-decoration:underline;
      color:#333;
    }
    &__subject{
      display:block;
      margin-bottom:14px;
      font-weight: 700;
      font-size: 20px;
    }
    .btn {
      position:absolute;
      bottom:20px;
      width: fit-content;
    }
  }
}
@media screen and (max-width: 1600px) {
  .teacher-item .image-wrapper{
    height:270px;
  }
}
