.changeLang{
    margin-bottom:20px;
    width:250px;
}
.user-form{
    margin-bottom:25px;
}
.white-div{
    margin-right:30px;
}
.white-div{
    & span{
       display:block;
       margin-bottom:3px;
       font-size:14px;
    }
    & .opacity{
        opacity:0;
    }
    &__select{
        height:32px;
        width:190px;
        text-indent:5px;
    }
}
.white-div__input{
    border-radius:2px;
    height:32px;
    border:1px solid #000;
}
.white-div .btn{
    height: 32px;
    line-height: 32px;
    padding: 0 30px;
    color:#333;
    &:hover{
        background-color:#3561ff;
        color:#fff;
    }
}
.white-div .btn-primary{
    color:#fff;
}