.prise-course{
  position:relative;
  &__top{
    display:flex;
    align-items:center;
    justify-content: space-between;
    margin-bottom:25px;
  }
  &__heading{
    display:block;
    font-weight:bold;    
    font-size: 32px;
    padding-top: 2px;
    font-family: "Rothek Bold", sans-serif;
  }
  &__btn{
    display: inline-block;
    background: linear-gradient(#847B7B, #000000);
    color: #fff;
    border-radius: 42px;
    padding: 20px;
    font-size: 15px;
  }
  &__area{
    display:flex;
    margin-right:-25px;
  }
  &__item{
    width:50%;
  }
}
.prise-card{
  background: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 1px 2px 0px rgb(49 53 72 / 5%);
  margin-right:25px;
  position:relative;
  &__top{
    height:80px;
    display:flex;
    align-items:center;
    border-bottom:1px solid #EFF0F4;
    padding:5px 25px 0px 25px;
    & img{
      margin-right:12px;
    }
  }
  &__heading{
    font-weight: bold;
    font-size: 24px;
    padding-top:2px;
    font-family: "Rothek Bold", sans-serif;
  }
  &__content{
    padding:35px;
  }
  &__list{
    list-style:none;    
    padding-bottom:15px;
    & li{
      margin-bottom:14px;
    }
  }
  &__lrow{
    display:flex;
    align-items:center;
    & span{
      background-color: #20945C;
      color: #fff;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      text-align: center;
      line-height: 20px;
      font-size: 11px;
      margin-right: 10px;
    }
  }
  &__area{
    display:flex;
    justify-content:space-between;
    margin-bottom:25px; 
    border-top: 1px solid #EFF0F4;
    padding-top:20px;   
  }
  & .my-btn i{
    margin-left:20px;
  }
  &__bot{
    display:block;
  }
  &__lrow .prise-card__no{
    background-color: #A8ACB9;
  }
}
.price-ul{
  list-style:none;
  margin-bottom:18px;
  & li{
    margin-bottom: 10px;
  }
}
.price-row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.price-row__title{
  position: relative;
  display: block;
  height: 25px;
  line-height: 25px;
}
.price-row__title:after{
  content: "";
  position: absolute;
  left: 110%;
  width: 620px;
  bottom: 7px;
  border-bottom: 2px solid #eee;
  line-height: 0;
}
.price-row__right{
  display: flex;
  align-items: center;
  background-color: #fff;
  position: relative;
  padding-left: 5px;
  height: 25px;
  line-height: 25px;
}
.price-row__old{
  width: 92px;
  padding-left: 15px;
  position: relative;
  white-space: nowrap;
}
.price-row__old span{
  position: relative;
}
.price-row__old span:after{
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: 0px;
  border-bottom: 1px solid #000;
}
.price-card__info {
  background-color: #3561ff14;
  padding: 15px 15px 15px 15px;
  border-radius: 5px;
  margin-bottom:20px;
}


.prise-line{
  position:relative;  
  &__subject{
    display:block;
    color:#343C58;
    font-size:15px;
    font-weight:500;
    margin-bottom:10px;
  }
  &__item{
    display:flex;
    justify-content:flex-start;
    align-items:flex-end;
    color:#6E758A;
    margin-bottom:10px;
  }
  &__price{
    font-size:32px;
    line-height:1;
    font-weight:bold;
    font-family: "Rothek Bold", sans-serif;
    margin-right:5px;
    color:#343C58;
  }
  &__month{
    margin-bottom:3px;
  }
  &__all{
    display:block;
    color:#3561FF;
    font-size:14px;
    & i{
      margin-left:10px;
      font-size:13px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .prise-card__top{
    padding: 0 25px;
  }
  .prise-card__content{
    padding:25px;
  }
  .prise-line__price{
    font-size:28px;
  }
}