.btn-add-course {
  width: auto;
}
.add-course{
  margin-bottom:25px;
  &__heading{
    display:block;
    font-weight:bold;
    font-size:18px;
    margin-bottom:20px;
  }
}
.course-elem {
  display: block;
  margin-right: 20px;
  input {
    display: none;
  }
  label {
    padding: 7px 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
    width: 100%;
    border: 1px solid #555;
    background-color: #f5f5f5;
    &:hover{
      background-color:#3561ff;
      color:#fff;
      cursor:pointer;
    }
  }
  input:checked + label {
    background-color: #0075ff;
    border:1px solid #3561ff;
    color: white;
  }
}
.add-course-wrapper {
  width:100%;
  box-shadow: 0 1px 2px 0px rgba(49, 53, 72, 0.05);
  background-color: #fff;
  display: inline-block;
  padding: 20px;
  font-weight: 700;
  border-radius: 14px;
  margin-bottom: 40px;
}
.course-elements {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.add-course-element {
  margin-bottom: 15px;
}
.add-course-element {
  label {
    margin-right: 8px;
  }
}
.les-row-flex-top{
  align-items:flex-start;
}