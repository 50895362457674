.course-info{
    background-color:#fff;
    margin-bottom:20px;
    &__top{
        display:flex;
        justify-content: space-between;
    }
    &__heading{
        display:block;
        font-size:24px;
        line-height:24px;
        font-weight:bold;
        font-family: "Rothek Bold", sans-serif;
        margin-bottom:18px;
    }
    &__bot{
        display:flex;
        & .btn{
            padding:10px 10px;
            width:45%;
            margin-right:15px;
            max-width:200px;
            font-size:14px;
            text-align:center;
            display: flex;
            justify-content: center;
            font-weight:normal;
            & img{
                height:15px;
            }
        }
    }
}
.back-btn{
    display:flex;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    padding: 5px 15px;
    color: #333;
    background-color: #f3f3f3;
    border-radius: 6px;
    font-size: 15px;
    text-decoration: none;
    cursor:pointer;
    & i{
        margin-right:5px;
    }
    &:hover{
        background-color:#2a4ecf;
        color:#fff;
        border:1px solid #2a4ecf;
    }
}
.month-info{
    display:flex;
    margin-bottom:20px;
    &__row{
        margin-right:15px;
    }
}
.course-penalty{
    display:flex;
}
.lesson-panel{
    background-color:#fff;
    border-radius:12px;
}
.lesson-list{
    list-style:none;
    display:flex;
    flex-wrap:wrap;
    margin-right:-25px;
    margin-bottom:-25px;
    & li{
        width:33.3%;
        margin-bottom:25px;
    }
}
@media screen and (max-width: 1380px) {
    .lesson-list li{
        width:50%;
    }
}
@media screen and (max-width: 800px) {
    .lesson-list li{
        width:100%;
    }
}
@media screen and (max-width: 600px) {
    .css-1usuzwp-MuiButtonBase-root-MuiTab-root{
        font-size:15px !important;
        padding:10px 11px !important;
        text-transform: initial !important;
        font-family: "Manrope", sans-serif !important;
    }
    .month-info {
        flex-direction:column;
        & > div{
            margin-bottom:5px;
        }
    }
}
@media screen and (max-width: 500px) {
    .css-1usuzwp-MuiButtonBase-root-MuiTab-root{
        padding:10px 10px !important;
    }    
}