.error-not-found {
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  top: 100px;
  h1 {
    font-size: 100px;
    padding-bottom: 0;
    font-weight: 900;
  }

  .btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 200px;
  }
}
.error-not-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
