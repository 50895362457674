.video-lesson{
  margin-bottom: 35px;
  &__top{
    display:flex;
    justify-content: space-between;
    align-items:center;
    margin-bottom:25px;
  }
  &__back{
    color:#3561FF;
    border:1px solid #3561FF;
    line-height:50px;
    font-size:14px;
    padding:0 25px;
    border-radius:4px;
    display:flex;
    align-items:center;
    font-weight: 600;
    & i{
      margin-right:10px;
    }
  }
  &__heading{
    display:block;
    font-weight:bold;
    color:#343C58;
    font-size:26px;
    font-family: "Rothek Bold", sans-serif;
    margin-bottom:12px;
  }
  &__date{
    color:#6E758A;
    display: flex;
    align-items: center;
    & i{
      margin-right:7px;
    }
  }
  &__img{
    width:100%;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin-bottom:30px;
    & iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__buttons{
    display:flex;
  }
}
.time-code{
  margin-bottom:25px;
  &__heading{
    display:inline-block;
    position:relative;
    margin-bottom:15px;
    padding-right:40px;
    &__arrow{
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 22px;
      height: 22px;
      background-color: #fff;
      border-radius: 50%;
      font-size: 12px;
      line-height: 22px;
      text-align: center;
      box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 15%);
    }
  }
}
.time-code-ul{
  background-color:#fff;
  list-style:none;
  border-radius:4px;
  & li{
    display:flex;
    justify-content:flex-start;
    align-items:center;
    border-bottom:1px solid #ccc;
    padding: 12px 20px;
    & i{
      margin-right:10px;
    }
    & span{
      color:#3561FF;
      margin-right:15px;
    }
  }
  
}

