.hw_list{
  list-style:none;
  background-color:#fff;
  border:1px solid #ccc;
  border-radius:5px;
  & li:last-child .hw-row{
    border-bottom:none;
  }
}
.hw-row{
  display:flex;
  border-bottom:1px solid #ccc;
  &--top{
    font-weight:bold;
    & .hw-row__item{
      background-color: #3561ff;
      color: white;
    }
  }
  &__item{
    flex-shrink:0;
    padding:15px;
    border-right:1px solid #ccc;
    display:flex;
    flex-direction:column;
    justify-content:center;
    font-size:15px;    
  }
  & &__item:last-child{
    border-right:none
  }
  &__number{
    width:10%;
  }
  &__one{
    width:35%;
  }
  &__two{
    width:35%;
  }
  &__three{
    width:16%;
  }
  &__four{
    width:16%;
  }
  &__five{
    width:20%;
  }
}