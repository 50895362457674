.group-table{
    border:1px solid #ccc;
    border-collapse: collapse;
}
.group-table tr td{
    padding:10px 15px;
    border-bottom:1px solid #ccc;
    border-right:1px solid #ccc;
}
.group-table tr td:first-item{
    min-width:100px;
}