.course-plan{
  background-color:#fff;
  margin-bottom:35px;
  &__top{
    display:flex;
    align-items:center;
    border-bottom: 2px solid #EFF0F4;
    padding-bottom:15px;
    margin-bottom:15px;
  }
  &__heading{
    display:block;
    font-weight:bold;
    font-family: "Rothek Bold", sans-serif;
    font-size:19px;
  }
}
.course-list{
  list-style:none;
  margin-bottom:22px;
  &__row{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:10px;
    padding-bottom:10px;
    border-bottom: 1px solid #EFF0F4;
  }
  &__date{
    font-size:15px;
  }
  &__lesson{
    font-size:15px;
    & span{
      color:#3561FF;
    }
  }
}