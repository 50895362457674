.course-list-item {  
  width:33.33%;
  margin-bottom:25px;
  &__wr{
    position: relative;
    padding: 20px;
    background: #ffffff;
    box-shadow: 19.7993px 28.049px 39.5985px rgba(0, 11, 51, 0.05);
    border-radius: 12px;
    margin-right:26px;
    height:100%;
  }
  .course-list-item__img{
    float:right;
    border-radius: 8px;
    height: 80px;
    width: 80px;
    overflow: hidden;
    & img{
      object-fit: cover;
      display:block;
      width:100%;
      height:100%;
    }
  }
  .course-list-item__name{
    display:block;
    font-size: 18px;
    font-weight: 600;    
    border-bottom: 1px solid #eff0f4;
    padding-bottom: 12px;
    margin-bottom:12px;
    margin-right:120px;
  }
  .course-details {
    display: flex;
    flex-direction: column;
    p {
      font-size: 13px;
      font-weight: 500;
      color: #a8acb9;
      margin-bottom:5px;
      & i{
        width:14px;
        margin-right:5px;
      }
      span {
        color: #6e758a;
      }
    }
  }
}
.course-list__bot {
  display: flex;
  margin-top: 18px;
  margin-right:-10px;
  &__btn{
    padding: 15px 22px;
  }
}
@media screen and (max-width: 1100px) {
  
  .course-list-item {  
    width:100%;
  }  
}