.courses-list {
  display: flex;
  list-style:none;
  margin-right:-30px;
}
@media screen and (max-width: 960px) {
  .prise-course__area .prise-course__item:nth-child(2){
    display:none
  }
  .prise-course__item{
    width:100%;
  }
  .otzyv-part__top p{
    display:none;
  }
}

@media screen and (max-width: 800px) {
  .course-top__left{
    display:none;
  }
  .course-top__content{
    margin-left:0;
  }
}