.top-div{
    margin-bottom:25px;
    padding:20px;
}
.select-part{
    display:flex;
    justify-content: space-between;
    align-items:center;
    margin-bottom:15px;
    border-bottom:1px solid #787878;
    padding-bottom:15px;
    &__left{
    display:flex;
    }
    &__item{
        margin-right:30px;
        & p{
            margin-bottom:5px;
        }
    }
    &__heading{
        flex-shrink:0;
        margin-right:8px;
    }
    .btn{
        margin-right:20px;
    }
}
.radio-label input{
    margin-right:5px;
}
