.popup-form{
  display:block;
  & .edit-form-row{
    margin-bottom:15px;
  }
  & .input{
    padding:15px;
  }
}
.date-row{
  margin-right:10px;
  & span{
    margin-right:5px;
  }
}
.edit-form-row__input{
  height:24px;
}
.ocourse-ul{
  margin-bottom:5px;
}