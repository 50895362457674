.modal-info p{
  margin-bottom:15px;
}
.modal__content--telegram{
  max-width:400px;
  min-width:auto;
  & .modal-content__heading{
    font-size:22px;
  }
}
.modal-info__inst{
  text-decoration: underline;
  cursor:pointer;
  &:hover{
    color:#3561FF;
  }
}