.my-course {
  .btn-group {
    padding: 10px 0;
    gap: 15px;
    .btn {
      display: flex;
      width: auto;
      padding: 18px 36px;
      align-items: center;
      justify-content: space-around;
      font-size: 16px;
      line-height:1;
    }
    i{
      margin-left: 10px;
    }
  }
}
.course-spisok{
  list-style:none;
  display:flex;
  flex-wrap:wrap;
  & > li{
    width:50%;
  }
}
.course-spisok .course-penalty{
  display:none;
}
.course-item{
  margin-right:20px;
  margin-bottom:20px;
}
.mycourse-list{
  list-style:none;
  display:flex;
  margin-bottom:20px;
  margin-top:-5px;
  & li{
    margin-right:15px;
  }
}
.course-link{
  background-color:#fff;
  padding:10px 20px;
  border-radius:5px;
  box-shadow: 0 1px 2px 0px rgba(49, 53, 72, 0.05);
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: #111;
  cursor:pointer;
  font-size:15px;
  &:hover{
    box-shadow: 0 3px 3px 1px rgb(16 18 28 / 10%);
  }
  &--active{
    color:#fff;
    background-color:#3561ff;
    &:hover{
      box-shadow: 0 1px 2px 0px rgba(49, 53, 72, 0.05);
    }
  }

}