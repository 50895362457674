.tests-subject-item {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border-radius: 16.5px;
  margin-right: 30px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  color: black;

  &:hover {
    box-shadow: 20px 28px 39.5px rgba(0, 11, 51, 0.05);
  }

  &-title {
    display: flex;
    font-weight: 700;
    font-size: 16px;
  }

  &-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 10px;
  }
}
