.tests-page {
  .title {
    display: block;
    color: #343c58;
    font-family: "Rothek Bold", sans-serif;
    font-weight: 900;
    margin: 0 0 16px 0;
    font-size: 25px;
  }

  .select {
    display: inline-block;
    width: 240px;
    margin-right: 20px;
  }

  .btn {
    border-radius: 5px;
    border: 0;
    width: auto;
    display: inline-block;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    transition: 0.05s linear;
    padding: 20px;
    background-color: #3561ff;
    font-weight: 700;
  }
  .flex .btn{
    margin-right:0;
  }
  .sub-title {
    font-size: 18px;
    margin-top: 30px;
  }
}
.mytest{
  margin-bottom:50px;
  &__top{
    display:flex;
    background-color: #3561ff;
    color: #fff;
    & div{
      padding: 14px 10px;
      font-weight:bold;
    }
  }
  &__id{
    width:5%;
  }
  &__subject{
    width:25%;
  }
  &__date{
    width:18%;
  }
  &__status{
    width:12%;
  }
  &__result{
    width:25%;
  }
  &__row{
    display:flex;
    background-color: #fff;
    border-bottom:1px solid #ccc;
    & div{
      padding: 14px 10px;
    }
  }  
}
.test-form{
  margin-bottom:35px;
}
@media screen and (max-width: 1200px) {
  .mytest__date{
    width:20%;
  }
  .mytest__status{
    display:none;
  }
}
@media screen and (max-width: 900px) {
  .test-form .flex{
    flex-direction: column;
  }
  .test-form .select{
    width:100%;
    margin-bottom:15px;
  }
}