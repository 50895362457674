.sort-part{
    margin-bottom:15px;
    display:flex;
    &__area{
        margin-right:15px;
        padding:0 15px;
        background-color:#fff;
        & label{
            padding:15px 0 15px 5px;
            display:inline-block;
        }
    }
}