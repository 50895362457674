.modal{
  width:100vw;
  height:100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position:fixed;
  top:0;
  left:0;
  display:flex;
  align-items:center;
  justify-content: center;
  transform:scale(0);  
}
.modal.active{
  transform:scale(1);
}
.modal__content{
  min-width:400px;
  padding:30px;
  border-radius:12px;
  background-color:white;
}
.modal__heading{
  display:block;
  padding-bottom:10px;
  border-bottom:1px solid #ccc;
  font-size: 24px;
  color: #343C58;
  font-family: "Rothek Bold", sans-serif;
  font-weight: bold;
  margin-bottom:20px;
}
.modal-kurs-list{
  list-style:none;
  margin-bottom:20px;
}
.subject-row{  
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #DFE1E6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__heading{
    font-weight:500;
    margin-right:10px;
    font-size:16px;
  }
  &__right{
    min-width:120px;
    margin-left:50px;
    display:flex;
    justify-content:flex-end;
  }
  &__add{
    border: 1px solid #3561ff;
    color: #3561ff;
    cursor: pointer;
    padding:5px 10px;
    border-radius:3px;
    background-color:#fff;
    font-size:14px;
    & i{
      font-size:12px;
      margin-left: 5px;
    }
    &:hover{
      background-color: #3561ff;
      color:#fff;
    }
  }
}
.modal-info{
  display:block;
  & input{
    width:100%;
    height:38px;
    margin-bottom:15px;
    text-indent:10px;
    border-radius:2px;
    border:1px solid #555;
  }
}
.modal-row{
  margin-bottom:15px;
  & label{
    display:block;
    margin-bottom:3px;
    font-size:14px;
  }
  &__input{
    width: 100%;
    height: 30px;
    border: 1px solid #777;
    text-indent: 5px;
    border-radius: 4px;
  }
}