.addcourse-list {
  margin-bottom: 2px;
  background-color: #fff;
  padding: 15px;
  position: relative;
  display:flex;
}
.addcourse-list label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-left: 40px;
  line-height: 48px;
}
.les-row {
  display: flex;
  align-items:center;
  margin-bottom: 15px;
  &--flex-top{
    align-items:flex-start;
  }
}
.les-row__name {
  width: 150px;
  text-align: right;
  font-weight: 500;
  line-height: 30px;
  margin-right: 10px;
}
.les-row input {
  border: 1px solid #ccc;
  height: 30px;
  border-radius: 3px;
}
.les-row__input {
  width: 300px;
}
.les-row__date {
  margin-right: 14px;
}
.les-row__date input {
  padding: 0 10px;
  width: 142px;
}
.add-inline{
  display:flex;
  align-items:center;
  margin-right:35px;
  &--first{
    width:200px;
  }
}
.flex{
  display:flex;
  & .btn{
    margin-right:20px;
  }
}
