.footer {
  grid-area: footer;
  background-color: #242424;
  color: #fff;
  padding: 30px 0;
  width: 100%;
  position: relative;
  &__wrapper{
    margin: 0 100px;
    display:flex;
    justify-content:space-between;
  }
  &__item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &--first{
      width:25%;
    }
    & p{
      font-size:15px;
      color:#fff;
      &:hover{
        cursor:pointer;
        text-decoration:underline;
      }
    }
    & a{
      color:#fff;
      text-decoration:none;
    }
  }
  &__heading{
    display:block;
    font-weight:bold;
    margin-bottom:5px;
    padding-bottom:10px;
    border-bottom:1px solid #fff;
  }
}
.footer-first{
  &__img{
    width:160px;
    display:block;
    display:block;
    margin-bottom:10px;
  }
  &__des{
    line-height:1.4;
    margin-bottom:22px;
  }
}
.footer-cont{
  list-style:none;
  & li{
    margin-bottom:15px;
    font-size:15px;
    & span{
      display:block;      
      margin-bottom: 5px;
      font-weight: bold;
    }
    &:last-child{
      margin-bottom:0;
    }
  }
}
.footer-links {
  display: flex;
  & img{
    margin-left:15px;
    width:25px;
    height:25px;
  }
}
@media screen and (max-width: 1200px) {
  .footer__wrapper{
    margin: 0 40px;
  }
}
@media screen and (max-width: 960px) {
  .footer{
    padding:25px 0;
    position:absolute;
    bottom:0;
  }
  .footer__item{
    display:none;
    &--first{
      display:block;
      width:100%;
    }
  }
  .footer-first{
    display:flex;
    justify-content: space-between;
    align-items: center;
    &__img{
      margin-bottom:0;
    }
  }
  .footer__item p{
    display:none;
    &:last-child{
      display:block;
    }
  }
}
