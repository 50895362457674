.subscription-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  .btn-group {
    width: 365px;
    gap: 15px;
    .btn {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
  .subscription-list {
    display: flex;
    gap: 10px;
    margin: 30px 0;
  }
}
.payment-item{
  background-color:#fff;
  padding:25px;
  &__title{
    display:block;
    font-weight:bold;
    font-size:18px;
    font-family: "Rothek Bold", sans-serif;
    margin-bottom:20px;
  }
}
.payment-row{
  margin-bottom:12px;
  &--last{
    margin-bottom:0;
  }
  &__title{
    font-weight:600;
    display:block;
    margin-bottom:5px;
  }
  & ul{
    margin-left:15px;
    font-size:15px;
    & li{
      margin-bottoM:5px;
      &:last-child{
        margin-bottom:0;
      }
    }
  }
}
.pay-top{
  margin-top:20px;
}
.payment-info{
  margin-top:25px;
  margin-bottom:20px;
}
.payment-info__heading{
  display:block;
  font-weight:bold;
  font-size:18px;
  margin-bottom:5px;
}
.payment-info__heading2{
  display:block;
  font-weight:bold;
  font-size:16px;
  margin-bottom:12px;  
}
.payment-text{
  border-top:1px solid #555;
  padding-top:15px;
}
.payment-text p{
  margin-bottom:10px;
  line-height:1.5;
}
.step-ul{
  margin-left:25px;
}
.step-ul li{
  margin-bottom:5px;
}
.payment-text__heading{
  font-weight:bold;
  font-size:16px;
}
.payment-bottom{
  margin-top:25px;
}