@import "./styles/normalise";
@import "./styles/buttons";
@import "./styles/input";
@import "./styles/fonts";

@font-face {
  font-family: "Rothek Bold";
  src: url("styles/Rothek/RothekBold.otf");
}
@font-face {
  font-family: "Rothek ExtraLight";
  src: url("styles/Rothek/RothekExtraLight.otf");
}

* {
  margin: 0;
  padding: 0;
  font-family: "Manrope", sans-serif;
  box-sizing: border-box;
}
html,body{
  height:100%;
}
body {
  background: #f3f3f3;
  color: #343c58;
}
#root{
  height:100%;
}
.container{
  max-width:1400px;
  margin:0 auto;
}
.fa-coins {
  color: #fac300;
}
.fa-crown {
  color: #fac300;
}
.title{
  display:block;
  color: #343c58;
  font-family: "Rothek Bold", sans-serif;
  font-weight: 900;
  margin: 0 0 18px 0;
  font-size: 26px;
  &--small{
    font-size:24px;
    margin-bottom:20px;
  }
}
.admin-title{
  display:block;
  color: #343c58;
  font-family: "Rothek Bold", sans-serif;
  font-weight: 900;
  margin: 0 0 25px 0;
  font-size: 27px;
}
.title span{
  font-size:24px;
}
.title--2{
  margin: 0 0 20px 0;
  font-size: 24px;
}
.title--subject{
  display:block;
  text-transform:uppercase;
  color: #343c58;
  font-family: "Rothek Bold", sans-serif;
  font-weight: 900;  
  font-size:18px;
  margin-bottom:18px;
  margin-top: 40px;
}
.box{
  background-color:#fff;
  padding:35px;
  border-radius:12px;
  box-shadow: 0 1px 2px 0px rgb(49 53 72 / 5%);
}
.box--top{
  margin-bottom:25px;
}
.box__heading{
  display:block;
  font-weight:bold;
  margin-bottom:15px;
}
.empty-lesson{
  margin-bottom:25px;
  font-weight: bold;
  font-family: "Rothek Bold", sans-serif;
  font-size: 16px;
}
.image {
  background-blend-mode: darken;
  width: 100%;
  height: 0;
  background-repeat: no-repeat;
  background-color: #f7f7f7;
  background-size: contain;
  background-position: center;
  padding-top: 66.64%;
}
.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3.29786px);
  border-radius: 50%;
  height: 66px;
  width: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: #fff;
  }
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.575);
    backdrop-filter: blur(4px);
  }
  &:active {
    background: rgba(0, 0, 0, 0.705);
    backdrop-filter: blur(104px);
  }
}
.flex{
  display:flex;
}
//breadcrums component
.breadcrumbs{
  display:flex;
  list-style:none;
  margin-bottom:15px;
  flex-wrap:wrap;
}
.breadcrumbs li{
  margin-right:15px;
}
.breadcrumbs li a{
  font-weight:bold;
  text-decoration:none;
}
.breadcrumbs li a:hover{
  color:#3561ff;
}
//breadcrums component end

//last lesson
.last-lesson{
  margin-bottom: 40px;
  &__heading{
    display:block;
    margin-bottom:15px;
    font-weight: 500;
  }
}


//lesson-card component


/*btn work component*/
.btn-work{
  color:#3561FF;
  border:1px solid #3561FF;
  text-decoration: none;
  padding: 9px 12px;
  font-size:14px;
  border-radius:4px;
  margin-right:15px;
  font-weight: 500;
  cursor:pointer;
  &:hover{
    background-color:#3561FF;
    color:#fff;
    transition: all 0.2s ease-in;
  }
}
.btn-work i{
  margin-left: 10px;
  position: relative;
  top: 1px;
}


.admintop-buttons{
  margin-bottom:20px;
  & .btn{
    display:inline-block;
    width: auto;
  }
}
.my-btn{
  display:inline-block;
  background-color:#3561FF;
  color:#fff;
  border:none;
  padding: 16px 20px;
  font-weight:bold;
  font-size:14px;
  border-radius:3px;
  text-decoration:none;
  cursor:pointer;
  & i{
    margin-left:10px;
  }
  &:hover{
    background-color:#2a54e7;
  }
}

// in admin page
.table{
  background-color:#fff;
  width:100%;
  box-shadow: 0 1px 2px 0px rgb(49 53 72 / 5%);
  border-collapse: collapse;
  table-layout: fixed;
  max-width:100%;
  & th, & td{
    padding:10px 8px;
    text-align:left;
    margin:0;
    font-size:15px;    
  }
  & th{
    padding: 14px 10px;
    background-color:#3561ff;
    color:#fff;
  }
  & tr{
    border-bottom:1px solid #ccc;
  }
  & td:first-child{
    width:50px;
  }
  &-fontsize th,&-fontsize td{
    font-size:14px,
  }
}
.item-control{
  &__item{
    display:flex;
    align-items:center;
    justify-content: center;
    border:1px solid #ccc;
    margin-right:15px;
    padding:0 15px;
    height:30px;
    border-radius:2px;
    background-color:#f3f3f3;
    font-size:14px;
    text-decoration:none;
    &:hover{      
      cursor:pointer;
      background-color:#3561ff;
      border:1px solid #3561ff;
      color:#fff;
    }
    & i{
      margin-right:8px;
    }
  }
}
.title-notes{
  list-style:disc;
  margin-left:20px;
  margin-bottom:15px;
}
.title-notes li{
  margin-bottom:5px;
}
.btn--dz{
  margin-bottom: 25px;
  display: inline-block;
}
.admin-heading{
  display:block;
  margin-bottom:15px;
}
.form-row{
  display: flex;
  align-items: center;
  margin-bottom:12px;
}
.form-row__conspect{
  display:block;
  color:#315af0;
  margin-bottom:5px;
  cursor:pointer;
}
.add-dz{
  background-color:#fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0px rgba(49, 53, 72, 0.05);
  padding:25px;
  margin-bottom:25px;
}
.form-row label {
  width: 120px;
  display: inline-block;
}
.form-row input {
  width: 500px;
  padding: 5px;
  font-size: 15px;
}
.form-row--main input {
  width:90%;
}
.form-row--main input{
  border:2px solid #333;
  border-radius:3px;
}
.form-row input[type="radio"]{
  width:auto;
  height: 16px;
  width: 16px;
}
.form-row__choice{
  margin-top: 15px;
  display:none;
  margin-bottom:25px;
}
.form-row--vyborka{
  display: block;
}
.form-row__choice.active{
  display:block;
}
.form-row__choice .form-choice__row:last-child{
  margin-bottom:0;
}
.form-choice__row{
  margin-bottom:15px;
  display:flex;
  align-items:center;
}
.form-choice__row label{
  width:120px;
}
.form-choice__row input[type="text"]{
  width:500px;
  height:30px;
  padding:0 5px;
}
.form-choice__row input[type="radio"]{
  width:18px;
  height:18px;
}
.form-choice__row div{
  margin-left:25px;
}
.qadmin-list{
  list-style:none
}
.qadmin-list{
  padding:25px;
  background-color:#fff;
}
.qadmin-list > li{
  margin-bottom:25px;
  padding-bottom:20px;
  border-bottom:1px solid #ccc;
}
.question-admin .group-table{
  margin-bottom:20px;
}
.question-admin__heading{
  margin-bottom:15px;
  font-weight:bold;
}
.question-admin__text{
  display:inline-block;
  margin-bottom:8px;
  position:relative;
  padding-right:25px;
}
.question-admin__text span{
  display:block;
}
.question-admin__file{
  position: absolute;
  left: 100%;
  top: -4px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.title__sub{
  margin-bottom:15px;
}
.question-admin__file:hover{
  background-color:#ccc;
}
.question-admin__file input{
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  opacity:0;
  z-index:5;
  cursor:pointer;
} 
.admin-choice{
  display:flex;
  align-items:center;
  position:relative;
}
.admin-choise__right{
  display:inline-block;
  position:relative;
}
.admin-choice__radio{
  width:25px;
  height:25px;
  margin-right:5px;
}
.admin-choice__checkbox{
  margin-right:15px;
}
.admin-choice label{
  margin-right:15px;
} 
.group-ul{
  list-style:none;
}
.group-ul li{
  margin-bottom:10px;
}
.group-ul li:last-child{
  margin-bottom:0;
}
.group-ul li .tipo-input{
  width:18px;
  height:18px;
}
.realadmin-choice-row__img{
  border:1px solid #777;
  width: 200px;
  margin-left: 0px;
  cursor:pointer;
  &:hover{
    opacity:0.85;
  }
}
.realadmin-choice-row__img img{
  width:100%;
  display:block;
}

.question-admin input[type=text]  {
  width: 500px;
  padding: 5px;
}

.question-admin__row{
  margin-bottom:15px;
}
.admin-choice-row{
  margin-bottom:20px;
}
.admin-choice-row__img{
  display:block;
}
.admin-choice-row__img img{
  max-width:100%;
  max-height:300px;
}
.btn-work.btn-work--full{
  background-color: #3561FF;
  color:#fff;
}
.btn-work.btn-empty{
  border:1px solid #ccc;
  color:#333;
}
.btn-work.btn-empty:hover{
  color:#fff;
}
.lesson-сard--admin{
  background-color: #fff;
  padding: 35px;
  border-radius: 12px;
  box-shadow: 0 1px 2px 0px rgb(49 53 72 / 5%);
  margin-bottom:15px;
  padding: 18px;
  position:relative;
}
.lesson-card__have{
  display:flex;
  flex-direction:column;
  justify-content: center;
}
.lesson-card__done{
  text-align:left;
  margin-bottom:5px;
  font-size:14px;
}
.item-part{
  margin-bottom:45px;
}
.user-month-ul{
  display:flex;
  list-style:none;
  margin-bottom:25px;
}
.user-month-ul li{
  margin-right:25px;
  padding:10px;
  background-color:#fff;
  font-size:15px;
  border-radius:3px;
  border:1px solid #ccc;
}
.period-pay{
  display:flex;
  &__item{
    margin-right:15px;
    & input{
      margin-right:5px;
    }
  }
}
.course-lang{
  display:flex;
  align-items:center;
}

/// search component for users.css
.search-block{
  background-color:#fff;
  padding:25px;
}


@media screen and (max-width: 1800px) {
  .container {
    max-width: 1200px;
  }  
}
@media screen and (max-width: 1600px) {
  .box{
    padding:25px;
  }
}
@media screen and (max-width: 900px) {
  .breadcrumbs li a,.breadcrumbs li span{
    font-size:15px;
  }
}
@media screen and (max-width: 780px) {
  .breadcrumbs li{
    margin-bottom:3px;
  }
  .breadcrumbs li a,.breadcrumbs li span{
    font-size:14px;
  }
}
@media screen and (max-width: 560px) {
  .breadcrumbs li a,.breadcrumbs li span{
    font-size:13px;
  }
}