.admin-user-top{
  margin-bottom:25px;
}
.admin-user-top .btn{
  padding:12px;
  font-size:14px;
}
.admin-user-ul{
    list-style:none;
    margin-bottom:15px;
}
.admin-user-ul li{
    margin-bottom:10px;
}
.admin-user-ul li:last-child{
    margin-bottom:0;
}
.admin-user-ul li span{
    font-weight:bold;
}
.addcourse-list{
    margin-bottom:2px;
    background-color:#fff;
    padding:15px;
    position:relative;
    display:flex;
    align-items:center;
}
.addcourse-list label{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-left: 40px;
    line-height: 48px;
}
.addcourse-list p{
    margin-bottom:0;
}
.margin-bot{
  margin-bottom:20px;
}
.tipo-input{
    width:20px;
    height:20px;
    border:1px solid #ccc;
    margin-right:9px;
}
.tipo-input--grey{
  background-color:grey;
}
.tipo-input:hover{
  background-color:#f3f3f3;
}
.form-user-update{
    margin-bottom:30px;
}
.form-update_part{
  display:flex;
  & button{
    margin-top: 25px;
    width: 200px;
    height: 40px;
    line-height: 40px;
    padding: 0;
  }
}
.form-update-row{
  margin-right:35px;
}
.form-update-row__label{
  display:block;
  margin-bottom:8px;
  font-weight:bold;
}
.form-update-row__input{
  border-radius:3px;
  padding:0 10px;
  height:38px;
  width:150px;
  border:1px solid hsl(0, 0%, 80%);
}
.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  
  .nav-tabs {
    border-bottom: 1px solid #dee2e6;
  }
  
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    font-weight:500;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
    isolation: isolate;
  }
  
  .nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
  }
  
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
  }
  
  .nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: 0.25rem;
  }
  
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #0d6efd;
  }
  
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #0d6efd;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  }
  .nav-link:focus,
  .nav-link:hover {
    color: #0a58ca;
  }
  
  .d-flex {
    display: flex !important;
  }
  
  .align-items-start {
    align-items: flex-start !important;
  }
  
  .flex-column {
    flex-direction: column !important;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
  }
  .me-3 {
    margin-right: 1rem !important;
  }
