.subscription-item {
  padding: 40px 30px;
  background: #ffffff;
  box-shadow: 19.7872px 28.0318px 39.5743px rgba(0, 11, 51, 0.05);
  border-radius: 16.4893px;
  .subscription-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    .cost {
      font-size: 14px;
      color: #6e758a;
      strong {
        color: #3561ff;
        font-size: 24px;
      }
    }
  }
  .courses-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 24px;
    border: 1px solid #eff0f4;
    border-left: 0;
    border-right: 0;
    li {
      font-size: 16px;
      text-overflow: ellipsis;
      word-break: break-all;
      font-weight: 500;
      
    }
    li::marker {
      color: #3561ff;
    }
  }
  .subscription-details {
    display: grid;
    gap: 9px;
    padding: 25px;

    p {
      i {
        padding: 0 5px;
      }
    }
  }
  .btn-group {
    display: flex;
    justify-content: left;
    .btn {
      height: 51px;
      padding: 16px;
      width: 100%;
      font-size: 13px;
      font-weight: 700;
      width: fit-content;
    }
  }
}
