.tests-ent-page {
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    display: block;
    color: #343c58;
    font-family: "Rothek Bold", sans-serif;
    font-weight: 900;
    margin: 0 0 16px 0;
    font-size: 36px;
  }

  .tabs {
    .react-tabs__tab-list {
      margin: 0;
    }

    .react-tabs__tab-panel--selected {
      background: white;
      padding: 14px 14px 0;
      border: 1px solid #aaa;
      border-top-width: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .mb-3 {
    margin: 0 !important;
  }

  .nav-link {
    cursor: pointer;
  }

  .btn {
    border-radius: 5px;
    border: 0;
    width: auto;
    display: inline-block;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    transition: 0.05s linear;
    padding: 20px;
    background-color: #3561ff;
    font-weight: 700;
  }

  .countdown {
    margin: 0 20px 0 auto;
    width: 100px;
  }

  .question {
    line-height: normal;
    margin-bottom: 14px;
    &__heading{
      display:block;
      font-weight:bold;
      margin-bottom:5px;
    }
    .name {
      font-weight: bold;
    }

    .checkbox {
      display: flex;

      > :first-child {
        margin: 0 10px;
      }

      input,
      label {
        cursor: pointer;
      }
    }
  }
  .question-variant{
    margin-bottom:2px;
    display:flex;
    position:relative;
    &__left{
      position:absolute;
      left:2px;
      margin-right:10px;
    }
    &__right{
      padding:0px 2px 0 20px;
      border-radius:2px;
      width:100%;
      &.green{
        background-color: #8ff28f;
      }
    }
  }
}
