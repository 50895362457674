.modal {
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: rgb(0 0 0 / 40%);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0);
}
.modal.active {
  transform: scale(1);
}
.modal__content {
  min-width: 400px;
  padding: 30px;
  border-radius: 12px;
  background-color: white;
}
.modal__header {
  display: block;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  font-size: 24px;
  color: #343c58;
  font-family: "Rothek Bold", sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
}
.popconfirm {
  &__btn-primary {
    display: inline-block;
    background-color: #3561ff;
    color: #fff;
    border: none;
    padding: 16px 20px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 3px;
    text-decoration: none;
    cursor: pointer;
    & i {
      margin-left: 10px;
    }
    &:hover {
      background-color: #2a54e7;
    }
  }

  &__btn-secondary {
    display: inline-block;
    background-color: #fff;
    color: #000;
    border: none;
    padding: 16px 20px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 3px;
    text-decoration: none;
    border: 1px solid black;
    cursor: pointer;
    & i {
      margin-left: 10px;
    }
    &:hover {
      background-color: #ccc;
    }
  }

  &__btn-danger {
    display: inline-block;
    background-color: #e60f0f;
    color: #fff;
    border: none;
    padding: 16px 20px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 3px;
    text-decoration: none;
    border: 1px solid #e60f0f;
    cursor: pointer;
    & i {
      margin-left: 10px;
    }
    &:hover {
      background-color: #b70808;
    }
  }
}
