.empty-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background-color: #fff;
  width: 100%;
  grid-area: header;
}
.empty-header__container{
  width: 900px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding:0 20px;
  & .logo {
    height: 80px;
    padding-left:0;
  }  
}
.header {
  grid-area: header;
  padding: 10px 40px;
  height: 98px;
  border-bottom: 1px solid #eff0f4;
  background-color: #fff;
  & .container{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__right{
    width: 100%;
    display:flex;
    justify-content: space-between;
    align-items:center;
  }
  &__dekright{
    display:flex;
    align-items:center;
    margin-left:25px;
  }
  img.logo {
    max-width: 1;
  }
  .user-info {
    display: flex;
    align-items: center;
    gap: 12px;
    .img {
      min-width: 57px;
      width: 57px;
      height: 57px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit:cover;
        background-color:#f3f3f3;
      }
    }
    .person-name {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: "Manrope", sans-serif;
      font-weight: 500;
      gap: 10px;
      position: relative;
      cursor: pointer;
      user-select: none;
      .fa-angle-down {
        font-size: 15px;
        margin-left: 3px;
      }
    }
    .fa-bell {
      color: #d6d7db;
      font-size: 24px;
    }
    i {
      font-size: 30px;
      text-align: center;
    }
  }
  .btn {
    justify-self: right;
    width: 200px;
    height: 48px;
    padding: 0;
    margin-left:25px;
  }
  &--teacher .btn{
    width: 120px;
    border: 2px solid #ff0000;
    text-align: center;
    font-weight: bold;
    justify-content: center;
  }
  &--teacher .btn:hover{
    background-color:#ff0000;
    color:#fff;
  }  
}
.header-logo{
  display:none;
  width:170px;
  & img{
    width:100%;
  }
}
.coins {
  position: relative;
  padding-right: 25px;
  font-size: 18px;
  font-weight: 700;
  border: 1px solid #3561ff;
  border-radius: 5px;
  padding: 5px 18px;
  height: 50px;
  display: flex;
  align-items: center;
  margin-left: 30px;
  text-decoration:none;
  transition: 0.15s linear;
  .fa-coins {
    margin-left:5px;
    transition: color 0.15s linear;
  }
  p {
    font-size: 14px;
    display:flex;
    color: #444;
    margin-right:5px;
    font-weight:400;
    transition:color 0.15s linear;
    span {
      color: #3561ff;
      margin-left:5px;
      font-size:15px;
      font-weight:bold;
      transition: color 0.15s linear;
    }
  }
  &:hover{
    background-color:#3561ff;
    color:#fff;
    & p{
      color:#f3f3f3;
    }
    & p span{
      color:#fff;
    }
  }
}
.advancedMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 230px;
  z-index: 1000;
  top: 70px;
  left: -25px;
  box-shadow: 0px 0px 20px rgb(0 11 51 / 5%);
  background-color:#fff; 
  border-radius:3px; 
  animation: showAdvancedAnimation 0.2s;
  a {
    text-decoration: none;
    display: block;
    padding: 17px;    
    color: #343c58;
    border-bottom:1px solid #EAEBF0;
    &:first-of-type {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &:last-of-type {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &:hover {
      background-color: #c4c4c4;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: -19px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -10;
    border-top: 10px solid transparent;
    border-bottom: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
}
.lang-ul{
  display:flex;
  list-style:none;
  & li{
    margin-right:5px;
    cursor:pointer;
    font-weight:bold;
    text-transform: uppercase;
    &.active{
      color:red;
      font-weight: bold;
    }
    &:hover{
      color:red;
    }
  }
}
.mob-start{
  display: none;
  width: 36px;
  height: 32px;
  position: relative;
  cursor: pointer;
}
.mob-start span{
  position: absolute;
  left: 0%;
  transition: all 290ms ease-in-out;
  display: block;
  width: 36px;
  height: 4px;
  border-radius: 2px;
  background-color: #000;
}
.mob-start .menu2 {
  top: 14px;
}
.mob-start .menu3 {
  top: 28px;
}
.mob-start--active .menu1 {
  top: 12px;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
.mob-start--active .menu2 {
  left: -20px;
  opacity: 0;
}
.mob-start--active .menu3{
  top: 12px;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}


@keyframes showAdvancedAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.notification {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .notification-count {
    top: 0;
    right: -5px;
    position: absolute;
    color: #fff;
    background: #ff0000;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    display: block;
    font-size: 10px;
    font-weight: 800;
    text-align: center;
    user-select: none;
  }
}
@media screen and (max-width: 1600px) {
  .header{
    height:80px;
  }
  .user-info__img{
    width:54px;
    height:54px;
  }
}
@media screen and (max-width: 1100px) {
  .mob-start{
    display:block;
    margin-right: 40px;
  }
  .header{
    position:fixed;
    top:0;
    left:0;
    right:0;
    z-index:2;
    height:70px;
    &__right{
      display:none;
    }
    & .container{
      justify-content: flex-start;
    }
  }
  .header-logo {
    display:block;
    margin-bottom: -4px;
  }
}
@media screen and (max-width: 960px) {
  .person-name p{
    font-size:0
  }
  .person-name p i{
    font-size:14px;
  }
  .coins{
    padding:0;
    border:none;
    & p{
      font-size:0px;
      & span{
        font-size:14px;
      }
    }
    & .fa-coins{
      font-size:25px;
    }
  }
}
@media screen and (max-width: 600px) {
  .mob-start{
    margin-right:30px;
  }
  .notification{
    display:none
  }
  .coins{
    margin-left:15px;
  }
  .lang-ul{
    font-size:14px;
  }
  .header .user-info{
    display:none;
  }
  .header__right{
    justify-content: flex-end;
  }
}
