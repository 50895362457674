.top-div{
    margin-bottom:25px;
    padding:20px;
}
.top-filter{
    margin-bottom:10px;
}
.white-div__select--big{
    width:250px;
}
.summary-salary{
    margin-bottom:15px;
}