.progress-bar {
  margin-bottom: 20px;

  .wrapper {
    margin: 20px 0 5px;
    display: flex;
    align-items: center;
  }

  &__description {
    font-weight: bold;
  }
}
