.lesson-container {
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 33px;
  background: #ffffff;
  box-shadow: 19.7699px 28.0073px 39.5398px rgba(0, 11, 51, 0.05);
  border-radius: 16.4749px;
  gap: 30px;
  .image-wrapper {
    width: 202px;
  }
  .lesson-wrapper {
    display: grid;
    justify-content: center;
    align-items: center;
    p {
      font-size: 13px;
    }
    .btn-group {
      gap: 10px;
      .btn {
        height: 51px;
        width: fit-content;
        font-size: 13px;
      }
    }
  }
  .dont-available {
    display: flex;
    background: #f5f7ff;
    border: 1px solid #dfe1e6;
    align-items: center;
    border-radius: 5px;
    font-size: 12px;
    height: 50px;
    h4 {
      padding: 17px;
      font-size: 24px;
      color: #a8acb9;
    }
    hr {
      height: 100%;
      border: 1px solid #dfe1e6;
    }
    div {
      padding: 17px;
      color: #6e758a;
      strong {
        color: #3561ff;
        cursor: pointer;
        user-select: none;
        text-decoration: underline;
      }
    }
  }
}
