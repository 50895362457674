.friend{
  padding:35px;
  max-width:1000px;
}
.friend-part{
  display:flex;
  align-items:center;
  margin-bottom:10px;
  &__left{
    margin-right:75px;
    max-width:55%;
    & p{
      margin-bottom:15px;
      line-height:24px;
    }
  }
  &__right{
    max-width:360px;
    display:block;
    margin-top: -20px;
    & img{
      display:block;
      width:100%;
    }
  }
}
.friend-how{
  margin-bottom:50px;
  &__heading{
    font-family: "Rothek Bold", sans-serif;
    display:block;
    font-weight:bold;
    font-size:25px;
    margin-bottom:15px;
  }
  &--last{
    margin-bottom:0;
  }
}
.how-ul{
  list-style:disc;
  padding-left:25px;
  margin-bottom:25px;
  & li{
    margin-bottom:10px;
  }
}
.note-friend{
  display:inline-block;
  font-weight:bold;
  margin-bottom:20px;
  line-height:1.3;
  & span{
    display:block;
    color: #3561ff;
    margin-bottom: 4px;
  }
}
.friend-link{
  background-color:#f1efff;
  padding:25px 30px;
  border-radius:20px;
  margin-bottom:30px;
  &__heading{
    display:block;
    font-weight:bold;
    margin-bottom:10px;
  }
}
.referal{
  background-color:#fff;
  padding: 15px 30px;
  border-radius:20px;
  display:flex;
  align-items:center;
  & p{
    margin-right:40px;
  }
  & .btn{
    max-width: 200px;
    padding: 14px;
    font-size: 15px;
  }
}
.table-friend{
  border:1px solid #ccc;
  margin-bottom:10px;
}
.friendpr-ul{
  display:flex;
  list-style:none;
  margin-top:15px;
  & li{
    width:40%;
    margin-right:5%;
  }
}
.friend-prize{
  display:flex;
  flex-direction:column;
  justify-content:center;
  text-align:center;
  align-items:center;
  &__heading{
    display:block;
    font-weight:bold;
    font-size:35px;
    margin-bottom:5px;
  }
  &__name{
    display:block;
    font-weight:bold;
    font-size:21px;
  }
  &__img{
    display:block;
    width:100%;
  }
}
.friend-result{
  background-color:#f1efff;
  padding:15px;
  display:inline-block;
  border-radius:5px;
  font-weight:bold;
}

@media screen and (max-width: 890px) {
  .friend-part{
    flex-wrap:wrap;
    &__left{
      width:100%;
      max-width:100%;
      margin-right:0;
    }
    &__right{
      width:100%;
      max-width:100%;
      display:flex;
      & img{
        width:400px;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .friendpr-ul{
    flex-wrap:wrap;
    & li{
      width:100%;
    }
  }
  .friend-prize__img{
    max-width:300px;
    width:100%;
  }
}