.prise-item {    
  &__wrapper{
    background: #fff;
    box-shadow: 4px 4px 18px rgb(0 11 51 / 5%);
    border-radius: 16px;
    margin-right:30px;
    overflow: hidden;
    position:relative;
    height:100%;  
  }
  &__img{
    background-color: #fff;
    height:250px;
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom:1px solid #f3f3f3;
    & span{
      position: absolute;
      background: #3561ff;
      border-radius: 4px;
      color: #ffffff;
      font-weight: 700;
      font-size: 13px;
      text-transform: uppercase;
      top: 10px;
      left: 10px;
      padding: 6px 10px;
    }
    & img{
      max-width: 70%;
      max-height: 85%;
    }
  }
  h2 {
    font-size: 18px;
    font-weight: 700;
    padding: 30px;
    background-color: #ffffff;
  }
}
