.login-container {
  display: flex;
  background: #ffffff;
  width: 900px;
  box-shadow: 24px 34px 48px rgba(0, 11, 51, 0.05);
  border-radius: 20px;
  overflow: hidden;
  & img {
    width: 50%;
    object-fit: cover;
  }
}

.login-form {
  width: 100%;
  position: relative;
  padding: 60px 45px 80px 45px;
  a {
    text-decoration: underline;
    color: #3561ff;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 500;
  }
  h1 {
    display: block;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 30px;
  }
  &__input {
    border: 0;
    outline: none;
    background-color: transparent;
    background: #f7f7f5;
    width: 100%;
    border: 1px solid #eff0f4;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .password {
    background: #f7f7f5;
    border: 1px solid #eff0f4;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    input {
      border: 0;
      padding-right: 5px;
      border-radius: 0;
      flex-grow: 1;
      margin-bottom: 0;
    }
    i {
      padding: 0 12px;
      cursor: pointer;
      color: #a8acb9;
    }
  }
  &__forget {
    margin-top: -20px;
    margin-bottom: 15px;
  }
  .btn {
    margin-bottom: 15px;
  }
}

.another-services {
  &__title {
    margin: 40px 0 10px;
    font-size: 14px;
    font-weight: 500;
  }

  &__buttons {
    display: flex;
    gap: 10px;

    .btn {
      width: 45px;
      height: 45px;
      padding: 0;

      &:nth-child(1) {
        background-color: #4178a9;
      }

      &:nth-child(2) {
        background-color: #dc4e41;
      }

      &:nth-child(3) {
        background-color: #3b5998;
      }
    }
  }
}

.register {
  position: absolute;
  bottom: 30px;
  left: 0;
  text-align: center;
  right: 0;
  font-size: 14px;
  font-weight: 500;
}
