// .lesson-card {
//   display: flex;
//   position: relative;
//   align-items: center;
//   margin-bottom: 20px;
//   &__img {
//     width: 130px;
//     height: 100px;
//     margin-right: 25px;
//     border-radius: 5px;
//     overflow: hidden;
//     flex-shrink: 0;
//     img {
//       display: block;
//       min-height: 100px;
//       height: 100%;
//       width: 100%;
//       object-fit: cover;
//     }
//   }
//   &__heading {
//     display: block;
//     font-weight: bold;
//     font-family: "Rothek Bold", sans-serif;
//     font-size: 17px;
//     margin-bottom: 16px;
//     margin-right: 120px;
//     & span {
//       color: #3561ff;
//     }
//   }
//   &__des {
//     max-width: 750px;
//     font-size: 14px;
//     line-height: 1.5;
//     margin-bottom: 22px;
//   }
//   &__buttons {
//     display: flex;
//   }
//   &__date {
//     width: 140px;
//     position: absolute;
//     top: 0;
//     right: 0;
//     background-color: #f5f7ff;
//     color: #3561ff;
//     font-size: 14px;
//     padding: 12px 15px;
//     font-weight: 500;
//     border-bottom-left-radius: 5px;
//   }
//   &__deadline {
//     width: 140px;
//     position: absolute;
//     top: 35px;
//     right: 0;
//     background-color: #f10000;
//     color: #ffffff;
//     font-size: 14px;
//     padding: 12px 15px;
//     font-weight: 500;
//     border-bottom-left-radius: 5px;
//   }
// }
.lesson-card.box{
  padding:20px;
}
.lesson-id {
  display: none;
}
