.editor-container {
  background: #ffffff;
  box-shadow: 19.7872px 28.0318px 39.5743px rgba(0, 11, 51, 0.05);
  border-radius: 16.4893px;
  padding: 48px 30px;
  .image-wrapper {
    float:left;
    width: 200px;
    height: 200px;
    border: 0;
    border-radius: 50%;
    overflow: hidden;
    background: #ccc;
    justify-self: right;
    display: flex;
    align-items: center;
    justify-content: center;
    position:relative;
    background-size: 80%;
    background-position: bottom center;
    background-repeat: no-repeat;
    margin-right: 25px;
    &:hover{
      background-color: rgb(0 0 0 / 22%);
      background-image: none !important;
    }
    input{
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      cursor:pointer;
      opacity: 0;
    }
    img {
      cursor: pointer;
      height: 100%;
      width: 100%;
      object-fit: cover;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .editor-wrapper {
    margin-left:230px;
    input {
      display:block;
      width: 100%;
      margin-bottom: 15px;
      padding: 14px 18px;
    }
    .btn-group {
      justify-content: flex-start;
      width: 100%;
      margin-top:12px;
      .btn {
        margin-right:15px;
        height: 62px;
        padding: 20px;
        width: 301px;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
  }
}
.edit-form-row label{
  margin-bottom:5px;
  display:block;
  font-size: 15px;
}
@media screen and (max-width: 800px) {
  .editor-container .image-wrapper{
    float:none;
    margin:0 auto 15px auto;
  }
  .editor-container .editor-wrapper{
    margin-left:0;
  }
  .editor-container .editor-wrapper input{
    padding: 13px 15px;
    font-size: 15px;
  }
}
