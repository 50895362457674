.analitycs-top{
  border-bottom:1px solid #ccc;
  display:flex;
  justify-content: space-between;
  padding-bottom:20px;
  margin-bottom:20px;
  &__heading{
    display:block;
    font-weight:bold;
    font-size:21px;
    margin-bottom:10px;
    font-family: "Rothek Bold", sans-serif;
  }
  &__row{
    color:#777;
    & i{
      margin-right:5px;
    }
  }
}
.student-solution{
  margin-top:10px;
  padding:15px;
  border: 1px solid #3561ff;
  border-radius: 6px;
  margin-top: 10px;
  padding: 15px;
  font-size: 15px;
  background-color: #fdf3f3;
  & label{
    display:block;
    margin-bottom:5px;
  }
}
@media screen and (max-width: 700px) {
  .analitycs-top{
    display:block;
    &__left{
      margin-bottom:6px;
    }
  }
  .lesson-analize__heading{
    font-size:18px;
  }
}