.register-container {
  display: grid;
  max-width:400px;
  width:100%;
  background: #ffffff;
  justify-content: center;
  align-items: center;
  box-shadow: 24px 34px 48px rgba(0, 11, 51, 0.05);
  border-radius: 20px;
  font-size: 12px;
  padding: 42px;
}

.register-form {
  display:block;
  h1 {
    text-align: center;
    font-size: 24px;
    margin:0 0 25px 0;
  }
  .register-checkbox {
    display: flex;
    gap: 10px;
    justify-content: space-around;
  }
  input {
    width: 100%;
    background: #f7f7f5;
    border: 1px solid #eff0f4;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
    margin-bottom:18px;
  }
  input[type="checkbox"] {
    background: #f7f7f5;
    border: 1px solid #eff0f4;
    box-sizing: border-box;
    border-radius: 3px;
    width: 15px;
    height:15px;
  }
  select{
    width:100%;
    margin-bottom:18px;
    height:55px;
    background: #f7f7f5;
    border:none;
    padding:0 10px;
  }
  & button{
    margin-top:20px;
  }
}
.register-public{
  display:flex;
  & input{
    width:40px;
    min-width:40px;
  }
}