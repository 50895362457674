.select-part{
    display:flex;
    align-items:center;
    margin-bottom:15px;
    &__item{
        margin-right:30px;
        & p{
            margin-bottom:5px;
        }
    }
    &__heading{
        flex-shrink:0;
        margin-right:8px;
    }
}