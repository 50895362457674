.item-control__item--change{
    display:inline-block;
    margin-top: 4px;
    height:22px;
}
.top-flex{
    display:flex;
    justify-content:space-between;
    margin-bottom:25px;
    align-items:center;
    & .title{
        margin-bottom:0;
    }
    & .btn{
        padding:12px 20px;
    }
}