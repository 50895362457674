.lesson-card {
  display: flex;
  position: relative;
  flex-direction:column;
  justify-content:space-between;
  border: 1px solid #3561ff;
  padding: 18px;
  border-radius: 6px;
  height: 100%;
  margin-right:25px;
  &__img {
    width: 130px;
    height: 100px;
    margin-right: 25px;
    border-radius: 5px;
    overflow: hidden;
    flex-shrink: 0;
    img {
      display: block;
      min-height: 100px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__heading {
    display: block;
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 15px;
    color:#333;
    text-decoration:none;
    &:hover{
      color:#3561FF;
    }
    & span {
      color: #3561ff;
    }
  }
  &__des {
    max-width: 750px;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 22px;
  }
  &__buttons {
    display: flex;
    font-size:14px;
    margin-top:8px;
    & .btn-work:last-child{
      margin-right:0;
    }
  }
  &__date {
    width: 140px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #f5f7ff;
    color: #3561ff;
    font-size: 14px;
    padding: 12px 15px;
    font-weight: 500;
    border-bottom-left-radius: 5px;
  }
  &__deadline {
    font-size: 14px;
    color: #777;
    font-weight: 500;
    border-radius:3px;
    margin-bottom:12px;
    & span{
      margin-right:5px;
    }
  }
  & .btn-work{
    display:inline-block;
  }
  &__end-date{
    margin-bottom:8px;
    font-size:14px;
    font-weight:500;
    color:#777;
    & span{
      margin-right:5px;
    }
  }
}
.lesson-id {
  display: none;
}
@media screen and (max-width: 470px) {
  .course-info .course-info__bot .btn{
    width:auto;
  }
  .course-info__bot .btn:last-child{
    margin-right:0px;
  }
  .MuiTabPanel-root {
    padding:22px !important;
  }
  .lesson-card{
    padding:15px;
  }
  .btn-work{
    padding: 9px 12px;
    margin-right: 13px;
  }
}
@media screen and (max-width: 420px) {
  .MuiTabPanel-root {
    padding:20px !important;
  }
  .course-info .course-info__bot{
    flex-direction:column;
    margin-bottom:-5px;
    & .btn{
      width:100%;
      max-width:100%;
      margin-bottom:5px;
    }
  }
  .btn-work{
    margin-right: 12px;
    & i{
      margin-left: 6px;
    }
  }
}
@media screen and (max-width: 390px) {
  .lesson-card {
    padding: 15px 0 0 0;
    border:none;
    border-top:1px solid #ccc;
    border-radius:0;
  }
}