.text p{
  margin-bottom:15px;
}
.app-wrapper {
  display: grid;
  grid-template-columns: 260px auto;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "navbar header"
    "navbar content"
    "footer footer";
  position: relative;
  min-height:100%;
}
.content {
  grid-area: content;
  padding: 40px 60px;
  overflow:hidden;
}
.content__inner {
  max-width: 1400px;
  margin:0 auto;
  position: relative;
}
.top30{
  margin-top:30px;
}
.minput-row{
  margin-bottom:15px;
}
.minput-row__label{
  display:block;
  margin-bottom:5px;
}
.form-update__row{
  margin-right:20px;
}
.white-row{
  display:flex;
  background-color:#fff;
  padding:25px;
  margin-bottom:20px;
  align-items:center;
  &__select{
    margin-right: 15px;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
  }
}
.white-row__item{
  display:flex;
  align-items:center;
  margin-right:20px;
}
.express-row input{
  margin-right:5px;
}
.white-row label{
  margin-right:5px;
}
.MuiTabs-flexContainer button:hover{
  color:#1976d2;
}
@media screen and (max-width: 1800px) {
  .content__inner {
    max-width: 1300px;
  }  
}
@media screen and (max-width: 1600px) {
  .content{
    padding:30px 35px;
  }
  .course-wrapper__title{
    font-size:18px;
  }
  .content__inner{
    max-width:1200px;
  }
}
@media screen and (max-width: 1400px) {
  .app-wrapper{
    grid-template-columns: 220px auto;
  }
}
@media screen and (max-width: 1100px) {
  .app-wrapper {
      display: block;      
  }
  .app-wrapper{
    padding-top:70px;
  }
  .content {
    padding: 20px 30px;
  }
}
@media screen and (max-width: 960px) {
  .app-wrapper {
      min-height: 100%;
      position: relative;
      padding-bottom: 84px;
  }
  .course-spisok > li{
    width:100%;
  }    
}

@media screen and (max-width: 700px) {
  .header {
    padding: 10px 30px;
  }
  .content {
    padding: 15px 25px;
  }
}
@media screen and (max-width: 500px) {
  .header {
    height:70px;
    padding: 5px 20px;
  }
  .navbar{
    top:70px;
    width:350px;
  }
  nav a svg{
    width:18px;
  } 
}
@media screen and (max-width: 500px) {
  .content {
    padding: 15px 20px;
  }
}